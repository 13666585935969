import React from 'react'
import Layout from '../layouts'
import './index.css'
import bgImage from '../images/pexels-gustavo-fring-3985153.jpg'
import logo1 from '../images/realtor.png'
import logo2 from '../images/buffini.png'
import logo3 from '../images/drs.png'
import logo4 from '../images/dignity-health.png'
import CertifiedAgent from '../components/certifiedAgent'

const PhysicanMedical = () => (
  <Layout head={{ title: ' Medical Students & Residents' }}>
    <div className="page-header-bg">
      <div className="container">
        <div className="header-text">
          {/* <p className="lead text-uppercase">Welcome Home</p> */}
          <h1 className="text-white text-uppercase">
            We are ready when you are
          </h1>
        </div>
      </div>
      <div
        className="header-bg"
        style={{
          backgroundImage: `url(${bgImage})`,
          zIndex: -1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '70%',
        }}
      />
    </div>

    <div>
      <div className="component bg-dirty-white">
        <div className="container">
          <div className="row justify-content-center mx-1">
            <div className="col-md-10">
              <div className="px-lg-4 text-center">
                <p>
                  The Brian Boals Team understands that your first move may be a
                  huge leap! That's why we keep you updated with real estate
                  trends as well as local and current events. We help physicians
                  in residency, as well as medical students, transition into
                  homeownership as they take on permanent positions at local
                  hospitals and clinics. We can help guide you in finding an
                  apartment or house to lease while you make the decision to
                  stay in our area for the long term.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CertifiedAgent />
      <div className="py-3 bg-dirty-white">
        <div className="container">
          <div className="d-flex justify-content-center flex-wrap">
            {logos.map(logo => (
              <div className="p-5">
                <img
                  src={logo.image}
                  alt={logo.alt}
                  style={{ width: '150px', height: 'auto' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default PhysicanMedical

const logos = [
  {
    image: logo1,
    alt: 'Realtor',
  },
  {
    image: logo2,
    alt: 'Buffini',
  },
  {
    image: logo3,
    alt: 'DRS',
  },
  // {
  //   image: logo4,
  //   alt: 'Dignity Health',
  // },
]
